<template>

<div class="dg-container">
   <!-- 将 logo 图片包裹在 <a> 标签中 -->
    <a href="http://www.dongge.com.cn/" target="_blank">
      <img src="@/assets/logo.png" alt="企业 Logo" class="logo" />
    </a>
  <div class="chat-container">
    <!-- 添加 logo -->
    <header class="chat-header">
      <h1>东鸽-DeepSeek-R1</h1>
      <h2 class="sub-title">AI 拓展了我们的能力边界</h2>
      <p class="description">激发了我们的想象力和创造力</p>
    </header>
    <form @submit.prevent="sendMessage" class="chat-form">
      <textarea v-model="userInput" class="chat-input"  placeholder="给 DeepSeek 发送消息" rows="4"></textarea>
      <button type="submit" class="chat-button">提问</button>
    </form>
    <div v-if="loading" class="loading-container">
      <div class="loader"></div>
      <p>思考中...</p>
    </div>
    <div v-if="response" class="response-container">
      <h2>AI 回复:</h2>
      <p class="reasoning">思考过程: {{ response.reasoning }}</p>

      <!-- <p class="answer">{{ response.answer }}</p> -->
      <p class="answer">{{ displayText }}</p>
  
    </div>
  </div>
  <iframe width="100%" height="100%" src="/lizi.html" frameborder="0"></iframe>

</div>
  

</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userInput: '',  // 用户输入的内容
      response: null, // 储存 AI 响应的内容
      loading: false,  // 加载状态
      displayText: '' // 用于逐字显示的文本
    };
  },
  methods: {
    async sendMessage() {
      if (!this.userInput) return; // 如果没有输入，直接返回

      this.loading = true; // 启用加载状态
      this.response = null;  // 清空之前的响应信息
      this.displayText = ''; // 清空显示文本

      try {
        // 调用后端接口
        // const res = await axios.post('http://localhost:3000/api/chat7', {
          const res = await axios.post('http://62.234.192.148:3000/api/chat7', {
          content: this.userInput
        });

        // 设置响应
        this.response = {
          answer: res.data.content || "无回复",
          reasoning: res.data.reasoning_content || "无思考过程"
        };
         // 调用逐字显示函数
         this.typeWriter(this.response.answer);
      } catch (error) {
        console.error('请求失败:', error);
        this.response = { 
          answer: '请求失败，请稍后再试。', 
          reasoning: '' 
        };
      } finally {
        this.loading = false;  // 请求完成后重置加载状态
      }
    },
    // 逐个字显示
    typeWriter(text) {
      let index = 0; // 当前字的索引
      const speed = 50; // 每个字母显示的间隔（毫秒）
      
      const timer = setInterval(() => {
        if (index < text.length) {
          this.displayText += text.charAt(index); // 显示下一个字
          index++;
        } else {
          clearInterval(timer); // 清除定时器
        }
      }, speed);
    }
  }
};
</script>



<style scoped>

  /* 主内容区域 */
  .dg-container {
  display: flex;
  flex-direction: column; /* 垂直布局 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  width: 40vw; /* 宽度占屏幕的40% */
  height: 70vh; /* 高度占屏幕的40% */
  max-width: 1200px; /* 最大宽度，防止在大屏上过大 */
  max-height: 800px; /* 最大高度，防止在大屏上过大 */
  margin: auto; /* 居中对齐 */
  /* overflow:visible ; */

}

.chat-container {
  width: 100%; /* 使聊天容器占满 dg-container 的宽度 */
  height: 100%; /* 使聊天容器占满 dg-container 的高度 */
  overflow: auto; /* 防止内容溢出 */
}

/* 将iframe的大小设置为dg-container的100% */
iframe {
  width: 100%; /* 自适应宽度 */
  height: 100%; /* 自适应高度 */
  border: none; /* 去掉边框 */
}


  .logo {
  /* width: 100px;  */
  width: 150px; 

  height: auto; /* 保持纵横比 */
  position: absolute; /* 绝对定位 */
  top: 20px; /* 距离顶部20px */
  left: 20px; /* 距离左侧20px */
  z-index: 2; /* 确保在其他元素之上 */
}

  iframe{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .chat-container {
    max-width: 600px;
    margin: 0 auto; /* 居中 */
    top: 80px;
    background-color: #1a1d3d; /* 深蓝色背景 */
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    animation: fadeIn 0.5s ease; /* 加入淡入特效 */
    position: relative; /* 确保子元素定位 */
    z-index: 1; /* 将聊天区域提升到上层 */


    /* overflow-y: visible; */




    /* max-height: 500px; 
  overflow-y: scroll; 
  position: relative; */

  }

  /* 隐藏滚动条的处理 */
  /* .chat-container::scrollbar {
  width: 0; 
  background: transparent; 
} */


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.chat-header {
  display: flex;
  flex-direction: column; /* 垂直排列 */
  align-items: center;
  margin-bottom: 20px;
}

.sub-title {
  /* font-size: 24px; */
  font-size: 1.5rem;

  color: #61dafb; /* 明亮科技感颜色 */
  margin-top: 5px;
}

.description {
  font-size: 16px;
  color: #ccc;
  text-align: center;
  margin-top: 5px;
}

.chat-form {
  display: flex;
  flex-direction: column;
}

.chat-input {
  resize: none;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 10px;
  background: #2a2a3d; /* 更深的输入框背景 */
  color: #ffffff;
  font-size: 16px;
  transition: background-color 0.3s; /* 背景颜色变化过渡 */
}

.chat-input::placeholder {
  color: #888; 
}

.chat-input:focus {
  background-color: #353540; /* 聚焦时的输入框背景 */
}

.chat-button {
  background-color: #007bff; /* 按钮背景色 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s; /* 添加变换效果 */
}

.chat-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 鼠标悬停时稍微放大 */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #61dafb; 
  border-radius: 50%;
  width: 30px; 
  height: 30px; 
  animation: spin 1s linear infinite; 
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.response-container {
  margin-top: 15px; 
  padding: 10px; 
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: slideIn 0.5s ease; /* 回应内容加入滑入特效 */


}




@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.answer {
  font-size: 16px; 
  font-weight: bold; 
  /* color: #404040; */
}

.reasoning {
  /* color: #ccc;  */
  color: #8b8b8b;
  line-height: 26px;
  font-size: 14px;
}
</style>
